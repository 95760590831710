const styles = {
  '10': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { //右2
    top: '25%',
    marginLeft: '40%',
    opacity: 0.7,
    width: '12%'
  }, { //右3
    top: '13%',
    marginLeft: '26%',
    opacity: 0.6,
    width: '9%'
  }, { //右4
    top: '10%',
    marginLeft: '12%',
    opacity: 0.5,
    width: '8.5%'
  }, { // 正后
    top: '8%',
    marginLeft: '0',
    opacity: 0.4,
    width: '8%',
  }, { //左4
    top: '10%',
    marginLeft: '-12%',
    opacity: 0.5,
    width: '8.5%'
  }, { //左3
    top: '13%',
    marginLeft: '-26%',
    opacity: 0.6,
    width: '9%'
  }, { //左2
    top: '24%',
    marginLeft: '-40%',
    opacity: 0.7,
    width: '12%'
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '9': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { //右2
    top: '25%',
    marginLeft: '40%',
    opacity: 0.7,
    width: '12%'
  }, { //右3
    top: '13%',
    marginLeft: '26%',
    opacity: 0.6,
    width: '9%'
  }, { //右4
    top: '10%',
    marginLeft: '12%',
    opacity: 0.5,
    width: '8.5%'
  }, { //左4
    top: '10%',
    marginLeft: '-12%',
    opacity: 0.5,
    width: '8.5%'
  }, { //左3
    top: '13%',
    marginLeft: '-26%',
    opacity: 0.6,
    width: '9%'
  }, { //左2
    top: '24%',
    marginLeft: '-40%',
    opacity: 0.7,
    width: '12%'
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '8': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { //右2
    top: '25%',
    marginLeft: '40%',
    opacity: 0.7,
    width: '12%'
  }, { //右3
    top: '13%',
    marginLeft: '26%',
    opacity: 0.6,
    width: '9%'
  }, { // 正后
    top: '8%',
    marginLeft: '0',
    opacity: 0.4,
    width: '8%',
  }, { //左3
    top: '13%',
    marginLeft: '-26%',
    opacity: 0.6,
    width: '9%'
  }, { //左2
    top: '24%',
    marginLeft: '-40%',
    opacity: 0.7,
    width: '12%'
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '7': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { //右2
    top: '25%',
    marginLeft: '40%',
    opacity: 0.7,
    width: '12%'
  }, { //右3
    top: '13%',
    marginLeft: '26%',
    opacity: 0.6,
    width: '9%'
  }, { //左3
    top: '13%',
    marginLeft: '-26%',
    opacity: 0.6,
    width: '9%'
  }, { //左2
    top: '24%',
    marginLeft: '-40%',
    opacity: 0.7,
    width: '12%'
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '6': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { //右2
    top: '25%',
    marginLeft: '40%',
    opacity: 0.7,
    width: '12%'
  }, { // 正后
    top: '8%',
    marginLeft: '0',
    opacity: 0.4,
    width: '8%',
  }, { //左2
    top: '24%',
    marginLeft: '-40%',
    opacity: 0.7,
    width: '12%'
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '5': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { //右2
    top: '25%',
    marginLeft: '40%',
    opacity: 0.7,
    width: '12%'
  }, { //左2
    top: '24%',
    marginLeft: '-40%',
    opacity: 0.7,
    width: '12%'
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '4': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { // 正后
    top: '8%',
    marginLeft: '0',
    opacity: 0.4,
    width: '8%',
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '3': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { //右1
    top: '45%',
    marginLeft: '24%',
    opacity: 0.95,
    width: '14%'
  }, { //左1
    top: '45%',
    marginLeft: '-24%',
    opacity: 0.9,
    width: '14%'
  }],
  '2': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, { // 正后
    top: '8%',
    marginLeft: '0',
    opacity: 0.4,
    width: '8%',
  }],
  '1': [{ //正中
    top: '47%',
    marginLeft: '0',
    opacity: 1,
    boxShadow: '0 1px 10px 0 #ffffff',
    width: '16%',
  }, ],
}
export default styles;
