<template>
    <div class="home">
        <div class="flex_box">
            <div class="top">
                <img class="logo" src="../assets/logo_home.png" />
                <img @click="logout" src="../assets/operate/nav_icon_logout.png" style="position:absolute;right:10px;top:10px;cursor:pointer;" />
            </div>
            <div class="center">
                <Systems class="systems" ref="systems" v-if="showApps.length" v-bind:appItems="showApps" v-on:selectApp="selectApp" v-on:selected="setAppStatus"></Systems>
                <transition name="cfade">
                    <!-- <div class="modules_box" v-if="isArrList" v-bind:style="modulesStyle"> -->
                    <div class="modules_box folding_box" v-if="isArrList">
                        <div class="side" v-for="(one,key) in arrList" v-bind:key="key">
                            <el-row type="flex" class="folding_flex">
                                <el-col :span="6" v-for="item in one" v-bind:key="item.logoUrl">
                                    <img :src="item.logoUrl" alt="">
                                    <p class="name_p" v-text="item.name"></p>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div class="modules_box" v-else v-bind:style="modulesStyle">
                        <div class="side no_padding">
                            <el-row>
                                <el-col :span="2" v-for="item in services" v-bind:key="item.logoUrl">
                                    <img :src="item.logoUrl" alt="">
                                    <p class="name_p" v-text="item.name"></p>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="footer">
                <img v-bind:class="{ gray: !currentAppStatus }" class="button" src="@/assets/home/button.png" width="100" @click="getAppToken" />
            </div>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import Systems from '@/components/center/systems'
import { MessageBox } from 'element-ui'

export default {
    name: 'home',
    data() {
        return {
            appItems: [],
            services: [],
            showApps: [],

            lock: false, // 动画锁，限制滑轮滚动幅度

            currentAppStatus: false, // 当前app是否有权限访问
            isHeighter: true,
            modulesStyle: {},
        }
    },
    // inject: ['reload'],
    components: {
        Systems,
    },
    watch: {
        isArrList: {
            handler: function() {
                this.setModulesStyle()
            },
            immediate: true,
        },
    },
    computed: {
        isArrList: function() {
            let isHeighter = this.isHeighter
            let length = isHeighter ? 10 : 4
            this.setModulesStyle()
            return this.services.length >= length || !isHeighter
        },
        arrList: function() {
            let list = []
            let l = []
            this.services.forEach((one, key) => {
                l.push(one)
                let ck = key - this.services.length / 2 + 1
                if (0 <= ck && ck < 1) {
                    list.push(l)
                    l = []
                } else if (key == this.services.length - 1) {
                    list.push(l)
                }
            })
            return list
        },
    },
    // activated() {
    //   this.getAppItems();
    // },
    created() {
        this.getAppItems()
        this.setIsH()
    },
    mounted() {
        window.addEventListener('mousewheel', this.handleScroll, true)
    },
    destroyed() {
        window.removeEventListener('mousewheel', this.handleScroll)
    },
    methods: {
        setModulesStyle() {
            this.$nextTick(() => {
                let centerBoxH = $('.center').height()
                let moduleBoxH = $('.modules_box').height()
                let footerOffTop = $('.footer').offset().top
                let marginTop = Math.floor(
                    (centerBoxH - footerOffTop - moduleBoxH) / 2
                )
                if (marginTop > 0) {
                    this.modulesStyle = {
                        marginTop: marginTop + 'px',
                    }
                } else {
                    this.modulesStyle = {}
                }
                console.log(this.modulesStyle)
            })
        },
        setIsH() {
            let height = $(window).height()
            if (height < 800) {
                this.isHeighter = false
            } else {
                this.isHeighter = true
            }
        },
        handleScroll(e) {
            if (this.lock) {
                return false
            }
            this.$refs.systems.slideNext(e.deltaY)
        },
        logout() {
            MessageBox.confirm('您确定退出该系统？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                // 注销
                // this.$util.requestPost(url, {}, () => {
                //     this.$util.removeStor('token')
                //     this.$router.push({ name: 'login' })
                // })
                let url = '/logout'
                this.$http.post(url).then(() => {
                    this.$util.removeStor('token')
                    this.$store.state.info = null
                    window.location.reload()
                    this.$router.push({ name: 'login' })
                })
            })
        },
        getAppItems() {
            let url = '/app/info/queryUserApp'
            this.$http.post(url).then(({ detail }) => {
                detail.forEach((item, index) => {
                    item.name = item.cName
                    item.icon = item.logoImg
                    item.url = item.addrLogin
                    item.key = index

                    let services_c = item.appServices
                    services_c.forEach(one => {
                        one.name = one.service
                        one.namicone = ''
                    })
                    item.services = services_c
                })
                this.appItems = detail
                this.services = detail[0].services
                this.setShowApps()
            })
        },
        selectApp(app) {
            this.lock = true
            this.services = app.services
            setTimeout(() => {
                this.setShowApps(app)
            }, 1000)
        },
        setShowApps(app) {
            let k = 0
            let apps = this.appItems

            var list = []
            if (apps.length > 10) {
                if (app) {
                    apps.forEach((item, key) => {
                        if (app.key == item.key) {
                            k = key
                        }
                    })
                }
                let appsList = []
                appsList = appsList.concat(apps.slice(k))
                appsList = appsList.concat(apps.slice(0, k))
                list = list.concat(appsList.slice(0, 5))
                list = list.concat(appsList.slice(-5))
            } else {
                list = apps
            }
            list.sort((item1, item2) => {
                return item1.key - item2.key
            })
            this.$set(this, 'showApps', list)
            this.lock = false
        },
        setAppStatus(app) {
            this.currentAppStatus = app.status
        },
        getAppToken() {
            this.$refs.systems.getAppToken()
        },
    },
}
</script>
<style lang="scss" scoped="home">
.cfade-enter,
.cfade-leave-to {
    opacity: 0.05;
}

.cfade-enter-active,
.cfade-leave-active {
    transition: opacity 0.8s; // 动画效果持续时长
}

.home {
    user-select: none;

    .flex_box {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        text-align: center;
        height: 100vh;
        background-image: url('../assets/home/bg_blue.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .top {
            padding-top: 3%;
        }

        .center {
            flex-grow: 1;
            width: 1000px;
            margin: auto;
            margin-top: 30px;
            display: flex;
            flex-flow: column nowrap;

            .systems {
                height: 400px;
            }

            .modules_box {
                width: 120%;
                display: flex;
                margin-left: -10%;

                &.folding_box {
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    margin-left: 0;
                    .side {
                        .folding_flex {
                            flex-wrap: wrap-reverse;
                            align-items: flex-start;
                            height: 100%;
                        }
                        &:nth-of-type(2) {
                            .folding_flex {
                                justify-content: flex-end;
                            }
                        }
                    }
                }

                .side {
                    flex: 1;
                    padding-right: 140px;
                    color: #fff;

                    .el-row {
                        .el-col {
                            margin-bottom: 20px;

                            .name_p {
                                margin-top: 10px;
                                font-size: 14px;
                            }
                        }
                    }

                    &:nth-of-type(2) {
                        padding-left: 140px;
                        padding-right: 0;

                        .el-row {
                            .el-col {
                                float: right;
                            }
                        }
                    }

                    &.no_padding {
                        padding: 0;
                        text-align: center;

                        .el-row {
                            .el-col {
                                float: none;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            position: fixed;
            bottom: 8%;
            left: 50%;
            margin-left: -50px;

            .button {
                width: 100px;
                height: 100px;
                align-self: center;
                border: none;
                color: #fff;
                font-size: 1.3rem;
                text-align: center;
                letter-spacing: 1px;
                cursor: pointer;
                border-radius: 2px;
                text-decoration: none;
                position: relative;
                bottom: 10%;
            }
        }
    }
}
</style>