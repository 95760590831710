<template>
    <div class="home_systems">
        <section class="main">
            <div class="apps">
                <!-- <svg class="track">
					<defs>
						<linearGradient id="stroke-img" x1="0%" y1="100%" x2="0%" y2="0%">
							<stop offset="0%" style="stop-color:rgba(25,119,190,0.96);stop-opacity:1" />
							<stop offset="100%" style="stop-color:rgba(163,201,229,0.96);stop-opacity:1" />
						</linearGradient>
					</defs>
					<ellipse cx="210" cy="45" rx="205" ry="43" fill="transparent" stroke="url(#stroke-img)" stroke-width="3" />
				</svg> -->
                <img class="track" src="../../assets/operate/ellipse.png" />
                <div v-for="(a, idx) in apps.items" :key="a.name+idx" class="app" :style="setAppsStyle(idx)" @click="select(a, idx)" v-bind:k='a.key'>
                    <!-- <img :src="a.icon" :title="a.appName" v-bind:class="{'grayscale':!a.status}" /> -->
                    <img :src="a.icon" :title="a.appName" />
                </div>
            </div>
            <!-- <img class="button" v-bind:class="{'gray':!apps.selected.status}" src="../../assets/home/button.png" width="100"  @click="getAppToken"/> -->
            <!-- <a class="button" v-on:click="getAppToken" v-bind:class="{'gray':!apps.selected.status}">进入系统</a> -->
        </section>
    </div>
</template>
<script>
import styles from './systems.appstyle'
export default {
    props: {
        appItems: {
            type: Array,
            default() {
                return []
            },
        },
    },
    activated() {
        this.$forceUpdate()
    },
    data() {
        let appItems = this.appItems
        console.log(appItems)
        return {
            apps: {
                selected: appItems[0],
                transitionDuration: 0.5,
                items: appItems,
                orders: [...Array(appItems.length).keys()],
            },

            currentIdx: 0,
        }
    },
    computed: {
        appsHalfNr() {
            return Math.ceil(this.apps.items.length / 2)
        },
    },
    watch: {
        appItems: {
            handler: function(val) {
                this.apps.items = val
                val.forEach(item => {
                    if (item.appId == this.apps.selected.appId) {
                        this.apps.selected = item
                    }
                })
            },
        },
        apps: {
            handler: function() {
                this.$emit('selected', this.apps.selected)
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        //点击某一个app
        select(app, idx) {
            console.log(app, idx)
            const order = this.apps.orders[idx]
            this.apps.selected = app
            const steps =
                order <= this.appsHalfNr
                    ? -order
                    : this.apps.items.length - order
            this.apps.transitionDuration = Math.abs(0.5 / steps)
            this.startAnimation(steps)

            this.currentIdx = idx

            this.$emit('selectApp', app)
        },
        slideNext(dir) {
            // 滑动到周围相邻的app
            const appsItems = this.apps.items

            if (dir > 0) {
                // 向右
                this.currentIdx++
                if (this.currentIdx >= appsItems.length) {
                    this.currentIdx = 0
                }
                this.select(appsItems[this.currentIdx], this.currentIdx)
            } else {
                //向左
                this.currentIdx--
                if (this.currentIdx < 0) {
                    this.currentIdx = appsItems.length - 1
                }
                this.select(appsItems[this.currentIdx], this.currentIdx)
            }
        },
        startAnimation(steps) {
            const orders = this.apps.orders.slice()
            const step = steps > 0 ? 1 : -1
            const app = step > 0 ? orders.shift() : orders.pop()
            if (undefined !== app) {
                step > 0 ? orders.push(app) : orders.unshift(app)
            }
            this.apps.orders = orders
            const next = steps - step
            if (next === 0) {
                return
            }
            setTimeout(() => {
                this.startAnimation(next)
            }, this.apps.transitionDuration * 1000)
        },
        //app样式
        setAppsStyle(idx) {
            var leng = this.apps.items.length
            var order = this.apps.orders[idx]
            console.log(leng)
            console.log(order)
            var styleObj = styles[leng][order]

            var top = styleObj.top
            var opacity = styleObj.opacity
            var marginLeft = styleObj.marginLeft
            var width = styleObj.width
            var left = styleObj.left

            var boxShadow = styleObj.boxShadow

            var style = {
                'transition-duration': this.apps.transitionDuration + 's',
                opacity: opacity,
                'margin-left': marginLeft,
                width: width,
                // 'height': width,
                top: top,
                'box-shadow:': boxShadow,
            }

            if (left) {
                delete style['margin-left']
                style.left = left
            } else {
                left =
                    50 -
                    Number(width.replace('%', '')) / 2 +
                    Number(marginLeft.replace('%', '')) +
                    '%'
                delete style['margin-left']
                style.left = left
            }

            return style
        },
        //获取app的token
        getAppToken() {
            let app = this.apps.selected
            console.log(app)

            // if (!app.status) {
            //     this.$toast('您没有进入权限')
            //     return
            // }
            // if (!app.loginUrl && app.id !== 20) {
            // 	console.log('no loginUrl');
            // 	return;
            // }

            if (/^(\/|#)/.test(app.appAddr)) {
                this.$router.push({ path: app.appAddr })
                console.log('1')
                return
            }

            if (!app.addr) {
                this.bankPage(app.appAddr)
                console.log('2')
                return false
            }

            var acount = this.$store.state.info
            let url = '/app/info/getAppToken'
            let params = {
                userName: acount.userName,
                password: acount.password,
                appId: app.appId,
            }
            this.$http.post(url, params).then(({ detail }) => {
                this.$set(app, 'token', detail.token)
                let url = app.appAddr
                console.log(app.appAddr)
                if (/\?/.test(url)) {
                    url += '&token=' + app.token
                } else {
                    url += '?token=' + app.token
                }
                console.log(url)
                this.bankPage(url)
            })
            // this.$util.requestPost(url, params, data => {
            //     this.$set(app, 'token', data.token)
            //     let url = app.appUrl
            //     if (/\?/.test(url)) {
            //         url += '&token=' + app.token
            //     } else {
            //         url += '?token=' + app.token
            //     }
            //     this.bankPage(url)
            // })
        },
        bankPage(url) {
            var id = 'bank_a'
            var a = null
            // 防止反复添加
            if (!document.getElementById(id)) {
                a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('target', '_blank')
                a.setAttribute('id', id)
                document.body.appendChild(a)
            } else {
                a = document.getElementById(id)
                a.setAttribute('href', url)
            }
            a.style.display = 'none'
            a.click()
        },
    },
}
</script>
<style lang="scss" scopde="home_systems">
.home_systems {
    .main {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;

        .apps {
            width: 100%;
            height: 100%;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            position: relative;

            .track {
                padding: 10%;
                width: 80%;
            }

            .app {
                position: absolute;
                cursor: pointer;
                transition-property: all;
                transition-timing-function: linear;
                border-radius: 50%;
                overflow: hidden;
                background: #ccc;

                img {
                    width: 100%;

                    &.grayscale {
                        filter: grayscale(0.9);
                    }
                }
            }
        }
    }
}
</style>